/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, image: metaImage, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            AGENDA_APP_SITE
            siteUrl
            defaultImage
          }
        }
      }
    `
  );

  const metaDescription = description
    ? description
    : site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  const image =
    metaImage && metaImage.url
      ? `${site.siteMetadata.AGENDA_APP_SITE}${metaImage.url}`
      : site.siteMetadata?.defaultImage;

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          "http-equiv": `cache-control`,
          content: "max-age=0",
        },
        {
          "http-equiv": `cache-control`,
          content: "no-cache",
        },
        {
          "http-expires": `expires`,
          content: 0,
        },
        {
          "http-expires": `expires`,
          content: "Tue, 01 Jan 1980 1:00:00 GMT",
        },
        {
          "http-expires": `pragma`,
          content: "no-cache",
        },

        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: "es_ES",
        },
        {
          property: `og:title`,
          content: title,
          // content: "PUCP | Agenda ",
        },
        {
          property: `og:site_name`,
          content: "PUCP | Agenda ",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:secure_url",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:secure_url",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: "320",
                },
                {
                  property: "og:image:height",
                  content: "320",
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `es_ES`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
};

export default SEO;
